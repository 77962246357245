import styles from "./BetaCodeCongrats.module.scss"
import congratsCodeBanner from "../../../../../../../assets/images/banners/congratsCodeBanner.png"
import { Title } from "../../../../../../../newComponents/Title/Title"
import { Text } from "../../../../../../../newComponents/Text/Text"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import React from "react"
import { PV_GP_MARKET } from "../../../../../sidebar.constants"
import cn from "classnames"

const BetaCodeCongrats = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.betaCodeCongrats}>
			<picture className={styles.betaCodeCongratsPicture}>
				<img src={congratsCodeBanner} alt="Congrats image" loading="lazy" />
			</picture>

			<Title tagName="h3" className={styles.betaCodeCongratsTitle}>
				{t("beta.modal.code.congrats.title")}
			</Title>

			<Text variant="s" className={styles.betaCodeCongratsText}>
				{t("beta.modal.code.congrats.desc")}
			</Text>

			<NavLink className={cn(styles.betaCodeCongratsButton)} to={PV_GP_MARKET} target="_blank" rel="noreferrer">
				{t("beta.modal.code.congrats.button")}
			</NavLink>
		</div>
	)
}

export default BetaCodeCongrats
