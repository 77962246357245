import React, { FC, HTMLAttributes } from "react"
import AdvTelBotBanner from "../../../../assets/images/banners/adv_tel_bot.png"
import cn from "classnames"
import "./SidebarBanner.scss"
import { NavLink } from "react-router-dom"

interface IBannerProps {
	className?: string
	bgImageUrl?: string
	link?: string
	target?: string
	onClick?: HTMLAttributes<HTMLButtonElement>["onClick"]
}

const SidebarBanner: FC<IBannerProps> = ({ className, bgImageUrl, link, target, onClick }) => {
	const picture = (
		<picture className="sidebar-banner__picture">
			<img src={bgImageUrl || AdvTelBotBanner} alt="Pixel Verse Telegram Bot" />
		</picture>
	)

	if (link) {
		return (
			<NavLink className={cn("sidebar-banner", className)} to={link} target={target || "_self"} rel="noreferrer">
				{picture}
			</NavLink>
		)
	}

	return (
		<button className={cn("sidebar-banner", className)} onClick={onClick}>
			{picture}
		</button>
	)
}

export default SidebarBanner
