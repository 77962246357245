import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution"
import { useAccount, useWriteContract } from "wagmi"

import Modal from "@mui/material/Modal"
import { ModalProps } from "@mui/material/Modal/Modal"
import { Zoom } from "@mui/material"
import { PurpleStackingGradient } from "../../../../assets/iconComponents/PurpleStackingGradient"
import { CloseIconPlain } from "../../../../assets/iconComponents/CloseIconPlain"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { SliderArrow } from "../../../../assets/iconComponents/SliderArrow"
import { StackingCard } from "./components/StackingCard"
import { HistoryCard } from "./components/HistoryCard"
import { StackingBackIcon } from "../../../../assets/iconComponents/StackingBackIcon"
import { StakeSubModal } from "./components/StakeSubModal"
import { GenesisWithdrawSection } from "./components/GenesisWithdrawSection"

import { stakingStore } from "../../store"
import { stakingService } from "../../service"
import { withdrawalService } from "../../withdrawalService"
import classNames from "classnames"

import { PenaltyType, RaffleState } from "../../types"

import PixelTkImg from "../../../../assets/images/pixel-token.png"

import "swiper/css"
import "swiper/css/navigation"
import css from "./styles.module.scss"

interface PropsTypes {
	title: string
	type: "history" | "withdraw"
	children: React.ReactNode
	titleValue: string | number
}

export const StackingModal = ({
	open,
	onClose,
	title,
	children,
	titleValue,
	type,
	...rest
}: PropsTypes & ModalProps) => {
	const isWithdrawType = type === "withdraw"
	const swiperRef = useRef<SwiperRef | null>(null)
	const isMobile = useIsMobileResolution()
	const { t } = useTranslation()

	const [subModalOpen, setSubModalOpen] = useState<boolean>(false)
	const [subStackingModalOpen, setStackingSubModalOpen] = useState<boolean>(false)
	const [ableToClaim, setAbleToClaim] = useState<boolean>(true)
	const [isGenesiwWithdrawModal, setGenesiwWithdrawModal] = useState<boolean>(false)
	const [withdrawCardsAmount, setWithdrawCardsAmount] = useState(0)

	const { stakers: data, genesis, history, newEpoch } = stakingStore()

	const { address } = useAccount()

	const totalWins = useMemo(() => {
		return history.filter(item => {
			const wallet = Array.isArray(item.wallet) ? item.wallet[0] : item.wallet
			return wallet && wallet.toLowerCase() === address?.toLowerCase()
		}).length
	}, [address, history])

	const { isPending, writeContractAsync: writeContract } = useWriteContract({
		mutation: {
			onSettled: () => {
				//@ts-ignore
				onClose()
			},
		},
	})

	useEffect(() => {
		if (isWithdrawType) {
			if (!data.length) {
				setStackingSubModalOpen(false)
			}
		} else {
			address && stakingService.getUserInformationAndProofsHistory(address)
		}
	}, [])

	// useEffect(() => {
	//   if (isSuccess || isError) {
	//     //@ts-ignore
	//     onClose();

	//     console.log("closed");
	//   }
	// }, [isSuccess, isError, onClose]);

	const handleGenesisWithdraw = async (penaltyType: PenaltyType, stakeIndex: number) => {
		if (address) {
			await withdrawalService.genesis.withdraw(writeContract, address, penaltyType, stakeIndex)
		}
	}

	const onToggleGenesisModal = () => {
		setGenesiwWithdrawModal(state => !state)
	}

	useLayoutEffect(() => {
		const length = genesis ? data.length + 1 : data.length

		setWithdrawCardsAmount(length)
	}, [data, genesis])
	return (
		<Modal
			open={open}
			onClose={onClose}
			classes={{
				root: css.modalRoot,
				backdrop: css.modalBackdropWithdraw,
			}}
			sx={{ outline: "none !important" }}
			{...rest}
		>
			<Zoom in={open} style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}>
				<section
					className={classNames(css.modalContent, { [css.modalContentGenesisWithdraw]: isGenesiwWithdrawModal })}
				>
					<div className={css.titleWrapper}>
						{<PurpleStackingGradient className={css.gradient} />}
						<div className={css.left}>
							<StackingBackIcon
								className={css.backIcon}
								onClick={() => {
									// @ts-ignore
									onClose()
								}}
							/>
							<hr className={css.divider} />
							<h2 className={css.titleText}>{title}</h2>
						</div>
						<div className={css.right}>
							{isWithdrawType ? (
								<div className={css.totalWrapper}>
									<span className={css.stackedLabel}>{"total staked:"}</span>
									<div className={css.stackedValue}>
										<img src={PixelTkImg} alt="token icon" className={css.tkImg} />
										{titleValue} PIXFI
									</div>
								</div>
							) : (
								<div className={css.totalWrapper}></div>
							)}
							<CloseIconPlain
								className={css.closeIcon}
								onClick={() => {
									// @ts-ignore
									onClose()
								}}
							/>
						</div>
					</div>

					{isGenesiwWithdrawModal && genesis && isWithdrawType ? (
						<GenesisWithdrawSection
							onToggleGenesisModal={onToggleGenesisModal}
							handleWithdraw={handleGenesisWithdraw}
							isPending={isPending}
						/>
					) : (
						<div className={css.cardsWrapper}>
							{isMobile ? (
								<>
									{genesis &&
										isWithdrawType &&
										genesis.map((el, i) => (
											<StackingCard
												key={`${el.id}${el?.penaltyType}${i}`}
												setSubModalOpen={setStackingSubModalOpen}
												{...el}
												onToggleGenesisModal={onToggleGenesisModal}
											/>
										))}

									{isWithdrawType
										? data.map((item, i) => (
												<StackingCard key={item.id + i} setSubModalOpen={setStackingSubModalOpen} {...item} />
											))
										: history.map((item, i) => {
												return (
													<HistoryCard
														key={item.id + i}
														setSubModalOpen={setSubModalOpen}
														epochIndex={+item.epochIndex}
														snapshotDate={(+item.epoch.epochEndTimestamp * 1000).toString()}
														lotteryHeldDate={new Date(+item.epoch.epochEndTimestamp * 1000)}
														contractAddress=""
														isEthChainConnected
														isWinner={item.status === RaffleState.won}
														ticketsAmount={item.amount}
													/>
												)
											})}
									<div style={{ height: 50 }} />
								</>
							) : (
								<Swiper
									key={withdrawCardsAmount}
									ref={swiperRef}
									modules={[Navigation]}
									slidesPerView={"auto"}
									spaceBetween={33}
								>
									{genesis &&
										isWithdrawType &&
										genesis.map(el => (
											<SwiperSlide
												key={`${el.penaltyType}staticItem`}
												style={{ display: "flex", justifyContent: "center" }}
												className={css.stakeSlideClass}
											>
												<StackingCard
													setSubModalOpen={setStackingSubModalOpen}
													{...el}
													onToggleGenesisModal={onToggleGenesisModal}
												/>
											</SwiperSlide>
										))}

									{isWithdrawType
										? data.map((item, i) => (
												<SwiperSlide key={item.id + i} style={{ display: "flex", justifyContent: "center" }}>
													<StackingCard setSubModalOpen={setStackingSubModalOpen} {...item} />
												</SwiperSlide>
											))
										: history.map((historyItem, i) => (
												<SwiperSlide key={historyItem.id + i} style={{ display: "flex", justifyContent: "center" }}>
													<HistoryCard
														setSubModalOpen={setSubModalOpen}
														epochIndex={+historyItem.epochIndex}
														snapshotDate={(+historyItem.epoch.epochEndTimestamp * 1000).toString()}
														lotteryHeldDate={new Date(+historyItem.epoch.epochEndTimestamp * 1000)}
														contractAddress=""
														isEthChainConnected
														isWinner={historyItem.status === RaffleState.won}
														ticketsAmount={historyItem.amount}
													/>
												</SwiperSlide>
											))}
									{data.length > 2 && (
										<SwiperSlide key={"staticItemEnd"} style={{ width: 32 }}>
											<div style={{ width: 32 }} />
										</SwiperSlide>
									)}
								</Swiper>
							)}
							<div className={css.btnsWrapper}>
								<div className={css.bottomDetails}>
									{!isWithdrawType && (
										<div className={css.totalWins}>
											{t("staking.history.totalWins")} <b>{totalWins}</b>
										</div>
									)}
								</div>
								<div className={css.btnsContentWrapper}>
									<button className={css.prevBtn} onClick={() => swiperRef?.current?.swiper.slidePrev()}>
										<SliderArrow className={css.arrow} />
										{t("staking.withdrawModal.left")}
									</button>
									<button className={css.nextBtn} onClick={() => swiperRef?.current?.swiper.slideNext()}>
										<SliderArrow className={css.arrow} />
										{t("staking.withdrawModal.right")}
									</button>
								</div>
							</div>
						</div>
					)}

					<div>{children}</div>

					<StakeSubModal
						open={subModalOpen}
						onClose={() => setSubModalOpen(false)}
						isWinner={true}
						subtitle={ableToClaim ? t("staking.stakingModal.Shards") : undefined}
						paragraph={ableToClaim ? t("staking.lockYourFunds") : undefined}
						btnText={ableToClaim ? "Take it" : undefined}
						onProceed={() => (ableToClaim ? setSubModalOpen(false) : setAbleToClaim(true))}
					>
						<></>
					</StakeSubModal>
					<StakeSubModal
						open={subStackingModalOpen}
						onClose={() => setStackingSubModalOpen(false)}
						onProceed={() => {
							stakingService.setState({ isCancelUnstakeSuccess: false })
							setStackingSubModalOpen(false)
						}}
					>
						<></>
					</StakeSubModal>
				</section>
			</Zoom>
		</Modal>
	)
}
