export const timer = (endDate: Date, returnAsObj = false): string | { label: string; value: string }[] => {
  const timeRemainingSeconds = Math.max((endDate.getTime() - new Date().getTime()) / 1000, 0);
  const days = Math.floor(timeRemainingSeconds / 86400).toString().padStart(2, '0');
  const hours = Math.floor((timeRemainingSeconds % 86400) / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((timeRemainingSeconds % 3600) / 60).toString().padStart(2, '0');
  const seconds = Math.floor(timeRemainingSeconds % 60).toString().padStart(2, '0');
  if (!returnAsObj) {
    return `${days}d:${hours}h:${minutes}m:${seconds}s`;
  } else {
    return [
      { label: 'd', value: days },
      { label: 'h', value: hours },
      { label: 'm', value: minutes },
      { label: 's', value: seconds },
    ];
  }
};
