import { BaseService } from "../BaseService"
import { BetaStore, betaStore } from "./store"
import { $api } from "../interceptor"
import { getErrorMessage } from "../../utils/getErrorMessage"

class BetaService extends BaseService<BetaStore> {
	constructor() {
		super(betaStore)
	}

	public async sendCode(code: string): Promise<void> {
		this.setState({ isCodeSending: true })
		try {
			const { data } = await $api.post<{ code: string }>("/beta-access-codes", { code })

			if (!data) return

			this.setState({ isCodeAccepted: true })
		} catch (e: unknown) {
			this.setState({ errorMessage: getErrorMessage(e) })
		} finally {
			this.setState({ isCodeSending: false })
		}
	}
}

export const betaService = new BetaService()
