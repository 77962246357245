import { avalancheFuji, mainnet } from "viem/chains"
import { isMainnet } from "./web3/contracts/constants"
import { PenaltyType } from "./types"

//TODO temporary make depend on dev|prod or env vatiable
const customMainnet = { ...mainnet }

export const APP_CHAIN = isMainnet ? customMainnet : avalancheFuji //APP_NETWORK_TYPE === "mainnet" ? avalanche : avalancheFuji

export const stakingDecimals = 18

export const GENESIS_LABELS: Record<PenaltyType, string> = {
	[PenaltyType.GENESIS]: "Genesis",
	[PenaltyType.STANDARD]: "Standard",
	[PenaltyType.NO_FEE]: "No fee",
}
