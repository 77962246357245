import { create, useStore } from "zustand"

export type BetaStore = {
	isShowBetaBanner: boolean
	isModalOpen: boolean
	isCodeSending: boolean
	isCodeAccepted: boolean
	errorMessage: string | null

	actions: {
		setModalOpen: (isModalOpen: boolean) => void
		clearErrorMessage: (errorMessage: string | null) => void
	}
}

export const betaStore = create<BetaStore>(set => ({
	isShowBetaBanner: false,
	isModalOpen: false,
	isCodeSending: false,
	isCodeAccepted: false,
	errorMessage: null,

	actions: {
		setModalOpen: (isModalOpen: boolean) => {
			set({ isModalOpen })
		},
		clearErrorMessage: (errorMessage: string | null) => {
			set({ errorMessage })
		},
	},
}))

export const useBetaStore = () => useStore(betaStore)
