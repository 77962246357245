import React from "react"
import classNames from "classnames"

import { useStakingStore } from "../../../store"
import { Trans, useTranslation } from "react-i18next"

import Social from "../../../../../components/Sidebar/components/Social/Social"
import { SliderArrow } from "../../../../../assets/iconComponents/SliderArrow"

import { PenaltyType } from "../../../types"

import css from "../styles.module.scss"
import { Loader } from "../../../../../components/Loader"

const GENESIS_PERCENT = 10

interface GenesisWithdrawSectionProps {
	withdrawAvailableDate?: string
	onToggleGenesisModal: () => void
	handleWithdraw: (penaltyType: PenaltyType, stakeIndex: number) => Promise<void>
	isPending: boolean
}

export const GenesisWithdrawSection: React.FC<GenesisWithdrawSectionProps> = ({
	onToggleGenesisModal,
	handleWithdraw,
	isPending,
}) => {
	const { t } = useTranslation()

	const { selectedGenesisItem, isGenesisWithdrawLoading } = useStakingStore()

	const withdraw = async () => {
		if (selectedGenesisItem?.penaltyType && selectedGenesisItem.stakeIndex !== undefined) {
			await handleWithdraw(selectedGenesisItem.penaltyType, selectedGenesisItem.stakeIndex)
			onToggleGenesisModal()
		} else {
			console.log("Unknown PType: ", selectedGenesisItem?.penaltyType)
		}
	}

	const genesisPercent =
		selectedGenesisItem?.penaltyType === PenaltyType.GENESIS
			? GENESIS_PERCENT
			: selectedGenesisItem?.penaltyType === PenaltyType.NO_FEE
				? 100
				: 0
	const isFullWithdrawAvailable = genesisPercent === 100
	const withdrawalAmount = ((genesisPercent / 100) * +(selectedGenesisItem?.amount || 0)).toFixed(2)
	const lostAmount = (
		+(selectedGenesisItem?.amount || 0) -
		(GENESIS_PERCENT / 100) * +(selectedGenesisItem?.amount || 0)
	).toFixed(2)

	return (
		<div className={css.genesisWithdrawWrapper}>
			<h3>{t("staking.genesisWithdraw.areYouSure")}</h3>

			<div className={css.genesisWithdrawWarning}>
				{!isFullWithdrawAvailable && (
					<div className={css.genesisWithdrawLabel}>{t("staking.genesisWithdraw.warning")}</div>
				)}

				<div className={css.genesisWithdrawPersentBlock}>
					<span className={css.genesisWithdrawPersent}>{genesisPercent}%</span>
					<div>
						<span className={css.genesisWithdrawAvaibleValue}>
							{t("staking.genesisWithdraw.availableToWithdraw")}
							<br />
							{withdrawalAmount} {t("airdrop.claim.pixfi.currency")}
						</span>
					</div>
				</div>
				{isFullWithdrawAvailable ? (
					<p>{t("staking.genesisWithdraw.fullWithdrawDescription")}</p>
				) : (
					<p>
						{t("staking.genesisWithdraw.atTheMoment")}
						<strong>
							<Trans i18nKey={"staking.genesisWithdraw.willLostForever"} values={{ value: `${lostAmount} ` }} />
						</strong>
					</p>
				)}
			</div>

			<div>
				<div className={css.genesisWithdrawWrapperSocials}>
					<div>
						{isFullWithdrawAvailable ? (
							<p>{t("staking.genesisWithdraw.followSocials")}</p>
						) : (
							<p>{t("staking.genesisWithdraw.WeWillNotify")}</p>
						)}
					</div>

					<div className={css.genesisWithdrawSocials}>
						<Social />
					</div>
				</div>

				<div className={css.withdrawBtnsWrapper}>
					<button
						onClick={onToggleGenesisModal}
						className={classNames(css.withdrawBtnCencel)}
						disabled={isGenesisWithdrawLoading}
					>
						{t("staking.genesisWithdraw.cancel")}
					</button>

					<button
						className={classNames(css.withdrawBtnWithdraw, { [css.green]: isFullWithdrawAvailable })}
						onClick={withdraw}
						disabled={isPending || isGenesisWithdrawLoading}
					>
						{isGenesisWithdrawLoading ? (
							<Loader color={isFullWithdrawAvailable ? "#83D987" : "#FF3D3D"} />
						) : (
							<SliderArrow className={css.arrow} stroke={isFullWithdrawAvailable ? "#83D987" : "#FF3D3D"} />
						)}
					</button>
				</div>
			</div>
		</div>
	)
}
