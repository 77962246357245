import React, { FormEvent, useState } from "react"
import { InputField, InputFieldErrors } from "../../../../../../../newComponents/Input"
import { useTranslation } from "react-i18next"
import { Btn } from "../../../../../../../newComponents/Btn"
import styles from "./BetaCodeForm.module.scss"
import { betaService } from "../../../../../../../services/beta/service"
import { useBetaStore } from "../../../../../../../services/beta/store"

const BetaCodeForm = () => {
	const { t } = useTranslation()
	const { errorMessage, isCodeSending, actions } = useBetaStore()

	const [betaCode, setBetaCode] = useState<string>("")

	const onBetaCodeChange = (value: string) => {
		if (errorMessage) {
			actions.clearErrorMessage(null)
		}

		setBetaCode(value)
	}

	const onBetaCodeSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		await betaService.sendCode(betaCode)
	}

	const codeInputStatus = errorMessage ? InputFieldErrors.ERROR : null
	const codeInputMessage = errorMessage || null

	return (
		<form className={styles.betaCodeForm} onSubmit={onBetaCodeSubmit}>
			<InputField
				className={styles.betaCodeInput}
				label={t("beta.modal.code.input.label")}
				type="text"
				placeholder={t("beta.modal.code.input.placeholder")}
				onChange={onBetaCodeChange}
				value={betaCode}
				status={codeInputStatus}
				message={codeInputMessage}
			/>

			<Btn
				type="submit"
				className={styles.betaCodeButton}
				isFullWidth
				disabled={isCodeSending || Boolean(errorMessage)}
				isLoading={isCodeSending}
			>
				{t("beta.modal.code.button")}
			</Btn>
		</form>
	)
}

export default BetaCodeForm
