import { useWriteContract } from "wagmi"
import { stakingService } from "./service"

import { PenaltyType } from "./types"

import { CONTRACTS_DATA } from "./web3/contracts"

type WriteContractType =
	| ReturnType<typeof useWriteContract>["writeContractAsync"]
	| ReturnType<typeof useWriteContract>["writeContract"]

const convertPenaltyTypeToNumber = (penaltyType: PenaltyType) => {
  switch (penaltyType) {
    case PenaltyType.GENESIS:
      return 0
    case PenaltyType.NO_FEE:
      return 1
    case PenaltyType.STANDARD:
      return 2
  }
}
export const withdrawalService = {
	genesis: {
		withdraw: async (
			writeContract: WriteContractType,
			address: `0x${string}`,
			penaltyType: PenaltyType,
			stakeIndex: number,
			isForce?: boolean,
		) => {
			try {
				stakingService.setGenesisWithdrawLoading(true)
				const signData = await stakingService.getWithdrawSignature(address, penaltyType, stakeIndex, !!isForce)
				const amount = BigInt(signData.amount)
				const used = signData.used
				const receiver = signData.receiver
				const signature = signData.signature

				if (used || !amount || !receiver || !signature) return

				await writeContract({
					address: CONTRACTS_DATA.withdraw.address,
					abi: CONTRACTS_DATA.withdraw.abi,
					functionName: "claim",
					args: [receiver, amount, convertPenaltyTypeToNumber(penaltyType), stakeIndex, signature],
				})
			} catch (e) {
				console.log("Failed to withdraw: ", e)
			} finally {
				stakingService.setGenesisWithdrawLoading(false)
			}
		},
		unstake: async (address: string, penaltyType: PenaltyType, stakeIndex: number) => {
			stakingService.setGenesisWithdrawLoading(true)
			const data = await stakingService.genesisUnstake(address, penaltyType, stakeIndex)
			stakingService.setGenesisWithdrawLoading(false)
			return data
		},
		cancelUnstake: async (address: string, penaltyType: PenaltyType, stakeIndex: number) => {
			stakingService.setGenesisWithdrawLoading(true)
			await stakingService.genesisCancelUnstake(address, penaltyType, stakeIndex)
			stakingService.setGenesisWithdrawLoading(false)
		},
	},
	standard: {
		withdraw: async (writeContract: WriteContractType, stakeId: number) => {
			try {
				writeContract({
					address: CONTRACTS_DATA.staking.address,
					abi: CONTRACTS_DATA.staking.abi,
					functionName: "withdraw",
					args: [stakeId, true],
				})
			} catch (e) {
				console.log("Failed to withdraw: ", e)
			}
		},
		unstake: async (writeContract: WriteContractType, stakeId: number) => {
			try {
				await writeContract({
					address: CONTRACTS_DATA.staking.address,
					abi: CONTRACTS_DATA.staking.abi,
					functionName: "unstake",
					args: [stakeId],
				})
			} catch (e) {
				console.log("Failed to unstake: ", e)
			}
		},
		cancelUnstake: async (writeContract: WriteContractType, stakeId: number) => {
			try {
				writeContract({
					address: CONTRACTS_DATA.staking.address,
					abi: CONTRACTS_DATA.staking.abi,
					functionName: "cancelUnstake",
					args: [stakeId],
				})
			} catch (e) {
				console.log("Failed to cancel unstake: ", e)
			}
		},
	},
}
