import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAccount, useWriteContract } from "wagmi"

import PixelTkImg from "../../../../../assets/images/pixel-token.png"
import { DisclaimerIcon } from "../../../../../assets/iconComponents/DisclaimerIcon"
import { StackingTimer } from "./StackingTimer"
import { PurpleStackingItemGradient } from "../../../../../assets/iconComponents/PurpleStackingItemGradient"
import { Question } from "../../../../../assets/iconComponents/Question"
import { PenaltyType, StakeDataType } from "../../../types"
import { Loader } from "../../../../../components/Loader"

import cn from "classnames"
import { getDMY } from "../../../../../utils/formatDate"
import { toBn } from "../../../../../utils/bigNumber"
import { getRewardPerDay } from "../../../../../utils/time"
import { stakingStore, useStakingStore } from "../../../store"
import { stakingService } from "../../../service"
import { withdrawalService } from "../../../withdrawalService"

import css from "../styles.module.scss"
import { GENESIS_LABELS } from "../../../constants"

export const StackingCard = ({
	setSubModalOpen,
	onToggleGenesisModal,
	...stake
}: StakeDataType & { setSubModalOpen: (c: boolean) => void }) => {
	const { t } = useTranslation()
	const [isFlipped, setIsFlipped] = useState(false)
	const [isTImerShown, setIsTImerShown] = useState<boolean>(stake.unstaked)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isWithoutPenalty, setIsWithoutPenalty] = useState<boolean>(false)

	const isTimerAbleToBeShown = stake.unstaked && stake.withdrawTimestamp && Date.now() <= +stake.withdrawTimestamp
	const { isPending, isSuccess, isError, variables, writeContractAsync: writeContract } = useWriteContract()

	const { address } = useAccount()

	const { isGenesisStakingLoading, isGenesisWithdrawLoading } = useStakingStore()

	const isDisabledByLoading = isGenesisStakingLoading || isGenesisWithdrawLoading

	useEffect(() => {
		if (variables?.functionName === "cancelUnstake" && isSuccess) {
			stakingStore.setState({ isCancelUnstakeSuccess: true })
			setSubModalOpen(true)
		}
	}, [variables, isSuccess, setSubModalOpen])

	const handleSelectGenesisItem = () => {
		stakingService.setSelectedGenesisItem(stake)
	}

	const handleUnselectGenesisItem = () => {
		stakingService.setSelectedGenesisItem(null)
	}

	const handleStandardUnstake = async () => {
		if (address) {
			await withdrawalService.standard.unstake(writeContract, stake.id)
			setIsTImerShown(true)
		}
	}

	const handleStandardCancelUnstake = async () => {
		if (address) {
			await withdrawalService.standard.cancelUnstake(writeContract, stake.id)
			setIsTImerShown(false)
		}
	}

	const handleGenesisUnstake = async () => {
		if (address && stake.penaltyType && stake.stakeIndex !== undefined) {
			const data = await withdrawalService.genesis.unstake(address, stake.penaltyType, stake.stakeIndex)
			if (data) {
				setIsTImerShown(true)
			}
		}
	}

	const handleGenesisCancelUnstake = async () => {
		if (address && stake.penaltyType && stake.stakeIndex !== undefined) {
			await withdrawalService.genesis.cancelUnstake(address, stake.penaltyType, stake.stakeIndex)
			setIsTImerShown(false)
		}
	}

	const handleUnstake = async () => {
		if (stake.isGenesis) {
			await handleGenesisUnstake()
		} else {
			await handleStandardUnstake()
		}
	}

	const handleCancelUnstake = async () => {
		if (stake.isGenesis) {
			await handleGenesisCancelUnstake()
		} else {
			await handleStandardCancelUnstake()
		}
	}

	const handlePreWithdraw = () => {
		if (stake.isGenesis) {
			stakingStore.setState({ unstakedTowithdrawId: null, selectedGenesisItem: stake })
		} else {
			stakingStore.setState({ unstakedTowithdrawId: stake.id, selectedGenesisItem: null })
		}
		setSubModalOpen(true)
	}

	const handleFlipCard = (state: boolean) => {
		switch (stake.penaltyType) {
			case PenaltyType.STANDARD: {
				handleUnselectGenesisItem()
				setIsFlipped(state)
				break
			}
			case PenaltyType.GENESIS: {
				handleSelectGenesisItem()
				onToggleGenesisModal && onToggleGenesisModal()
				break
			}
			case PenaltyType.NO_FEE: {
				handleSelectGenesisItem()
				onToggleGenesisModal && onToggleGenesisModal()
				break
			}
			default: {
				handleUnselectGenesisItem()
				setIsFlipped(state)
			}
		}
	}

	const penaltyDeadline = useMemo(() => {
		const genesisDeadline = stake.untilWithdrawDate ? new Date(stake.untilWithdrawDate).getTime() : 0
		const deadline = genesisDeadline || +stake.stakedTimestamp + stake.penaltyDays * 24 * 60 * 60 * 1000
		return {
			deadline,
			isPassed: toBn(new Date().getTime()).gte(deadline),
		}
	}, [stake.untilWithdrawDate, stake.stakedTimestamp, stake.penaltyDays])

	const isContractIteraction = isPending && !isSuccess && !isError

	return (
		<div className={cn(css.stakeCardWrapper, isFlipped && css.stakeCardWrapperFlipped)}>
			{isFlipped && <PurpleStackingItemGradient className={css.itemGradient} />}
			<div className={css.stakeCardFront}>
				<div className={css.stackingTop}>
					<span className={css.stackingDate}>{getDMY(new Date(+stake.stakedTimestamp))}</span>
					<button className={css.unstakeBtn} onClick={() => handleFlipCard(true)}>
						{stake.unstaked ? t("staking.stakingCard.details") : t("staking.stakingCard.unstrake")}
					</button>
				</div>
				<div className={css.stackingBottom}>
					{onToggleGenesisModal && stake.penaltyType && (
						<div className={css.genesisLabelWrapper}>
							<span className={css.genesisLabel}>{GENESIS_LABELS[stake.penaltyType]}</span>
						</div>
					)}

					<p className={css.stakeAmount} style={stake.amount.length > 7 ? { fontSize: "16px" } : {}}>
						<span>{stake.amount} PIXFI</span>
						<img src={PixelTkImg} alt="token-icon" />
					</p>
					<p className={css.stakePerDay}>
						<b>{getRewardPerDay(stake.rewardPerSecond).toFixed(2)}</b> <span>{t("staking.stakingCard.perDay")}</span>
					</p>
				</div>
			</div>

			<div className={css.stakeCardBack}>
				<div className={css.stakeCardBackHeader}>
					<span className={css.stackingDate}>{getDMY(new Date(+stake.stakedTimestamp))}</span>
					{stake.unstaked && (
						<button
							className={cn(css.unstakeBtn, isTImerShown && css.unstakeBtnActive)}
							onClick={() => {
								if (isTimerAbleToBeShown) {
									setIsTImerShown(prev => !prev)
								}
							}}
						>
							{"Unstaked"}
							{isTimerAbleToBeShown && <Question className={cn(css.questionIcon)} isBlack={isTImerShown} />}
						</button>
					)}
				</div>
				<p className={css.stakeAmount} style={stake.amount.length > 7 ? { fontSize: "16px" } : {}}>
					<span>{stake.amount} PIXFI</span>
					<img src={PixelTkImg} alt="token-icon" />
				</p>
				<p className={css.stakePerDay}>
					<b>{getRewardPerDay(stake.rewardPerSecond).toFixed(2, 1)}</b> <span>{t("staking.stakingCard.perDay")}</span>
				</p>
				{penaltyDeadline.isPassed ? (
					<p className={css.withdrawalWithoutPenalty}>{t("staking.stakingCard.youCanWithdraw")}</p>
				) : isTImerShown && stake.unstaked ? (
					<StackingTimer
						date={+stake.stakedTimestamp}
						untilPenaltyDate={penaltyDeadline.deadline}
						setIsWithoutPenalty={setIsWithoutPenalty}
					/>
				) : (
					<div className={css.disclaimer}>
						<DisclaimerIcon className={css.disclaimerIcon} />
						<p>{t("staking.stakingCard.disclamerDesc")}</p>
					</div>
				)}
				<div className={css.stakeBtnsWrapper}>
					{stake.unstaked ? (
						<button
							className={css.stakeBtnPurple}
							onClick={handleCancelUnstake}
							disabled={isContractIteraction || isDisabledByLoading}
							style={isContractIteraction ? { opacity: "0.5" } : {}}
						>
							{isDisabledByLoading ? <Loader color="#FFFFFF" /> : "Cancel unstake"}
						</button>
					) : (
						<button
							className={css.stakeBtnPurple}
							onClick={() => handleFlipCard(false)}
							style={isContractIteraction ? { opacity: "0.5" } : {}}
							disabled={isDisabledByLoading}
						>
							Cancel
						</button>
					)}

					{stake.unstaked ? (
						<button
							className={css.stakeBtnGrey}
							onClick={handlePreWithdraw}
							disabled={isContractIteraction || isDisabledByLoading}
						>
							{isContractIteraction || isDisabledByLoading ? <Loader /> : t("staking.stakingCard.withdraw")}
						</button>
					) : (
						<button
							className={css.stakeBtnGrey}
							onClick={handleUnstake}
							disabled={isContractIteraction || isDisabledByLoading}
						>
							{isContractIteraction || isDisabledByLoading ? <Loader /> : t("staking.stakingCard.unstake")}
						</button>
					)}
				</div>
			</div>
		</div>
	)
}
