import { useTranslation } from "react-i18next"
import { useAccount, useWriteContract } from "wagmi"

import Modal, { ModalProps } from "@mui/material/Modal/Modal"
import { Zoom } from "@mui/material"
import { SubModalCloseIcon } from "../../../../../assets/iconComponents/SubModalCloseIcon"
import Vincent from "../../../../../assets/images/vincent.png"
import Reward from "../../../../../assets/images/reward.png"
import LightBubble from "../../../../../assets/images/submodal-light-bubble.png"
import Chest from "../../../../../assets/images/chest.png"
import WinnerGradient from "../../../../../assets/images/win-gradient.png"
import { Loader } from "../../../../../components/Loader"

import cn from "classnames"
import { stakingStore } from "../../../store"
import { withdrawalService } from "../../../withdrawalService"

import css from "../styles.module.scss"
import { PenaltyType } from "../../../types"

export const StakeSubModal = ({
	isWinner,
	onProceed,
	onClose,
	open,
	subtitle,
	paragraph,
	btnText,
	...rest
}: ModalProps & {
	isWinner?: boolean
	subtitle?: string
	paragraph?: string
	btnText?: string
	onProceed: () => void | Promise<void>
}) => {
	const { t } = useTranslation()
	const { stakeId, isGenesisWithdrawLoading } = stakingStore(state => ({
		stakeId: state.unstakedTowithdrawId,
		isGenesisWithdrawLoading: state.isGenesisWithdrawLoading,
	}))

	const { isPending, writeContract } = useWriteContract({
		mutation: {
			onSettled: () => {
				//@ts-ignore
				onClose()
			},
		},
	})
	const { address } = useAccount()
	const { isCancelUnstakeSuccess, selectedGenesisItem } = stakingStore()

	const handleWithdraw = async () => {
		if (address) {
			if (stakeId !== null && !selectedGenesisItem) {
				try {
					await withdrawalService.standard.withdraw(writeContract, stakeId)
				} catch (e) {
					console.log("Failed to withdraw: ", e)
				} finally {
					stakingStore.setState({ unstakedTowithdrawId: null })
				}
			} else {
				selectedGenesisItem?.penaltyType &&
					selectedGenesisItem.stakeIndex !== undefined &&
					(selectedGenesisItem.penaltyType === PenaltyType.STANDARD
						? await withdrawalService.genesis.withdraw(
								writeContract,
								address,
								selectedGenesisItem.penaltyType,
								selectedGenesisItem.stakeIndex,
								true,
							)
						: await withdrawalService.genesis.withdraw(
								writeContract,
								address,
								selectedGenesisItem.penaltyType,
								selectedGenesisItem.stakeIndex,
							))
			}
		}
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			classes={{
				root: css.modalRoot,
				backdrop: css.modalBackdrop,
			}}
			sx={{ outline: "none !important" }}
			{...rest}
		>
			<Zoom in={open} style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}>
				<div className={css.subModalWrapper}>
					<img src={isWinner ? WinnerGradient : LightBubble} alt="bubble" className={css.bubbleIcon} />
					<img
						src={isWinner ? Chest : isCancelUnstakeSuccess ? Reward : Vincent}
						alt="content"
						className={cn(
							css.contentIcon,
							isCancelUnstakeSuccess && css.contentIconWithdrawn,
							isWinner && css.contentIconWinner,
						)}
					/>
					<header className={css.subModalHeader}>
						<h3 className={css.subModalTitle}>{isWinner ? "You won" : "Withdraw"}</h3>
						<SubModalCloseIcon
							className={css.subCloseIcon}
							onClick={() => {
								// @ts-ignore
								onClose()
							}}
						/>
					</header>
					<div className={css.subModalFooter}>
						<p className={css.subtitle}>
							{subtitle
								? subtitle
								: isWinner
									? t("staking.stakeSubModal.openToTakeRewards")
									: isCancelUnstakeSuccess
										? t("staking.stakeSubModal.youWillReceive")
										: t("staking.stakeSubModal.AreYouSure")}
						</p>
						<p className={css.subtitleLight}>
							{paragraph
								? paragraph
								: isWinner
									? t("staking.lockYourFunds")
									: isCancelUnstakeSuccess
										? "After canceling unstake you will get all Drop Tickets for the past cooldown period. Also you will remain in the same yield pool that you were in before the unstake."
										: "After canceling unstake you will get all Drop Tickets for the past cooldown period. Also you will remain in the same yield pool that you were in before the unstake."}
						</p>
						{isWinner ? (
							<button className={css.stakeBtnPurple} onClick={onProceed}>
								{btnText ? btnText : "Open"}
							</button>
						) : !isCancelUnstakeSuccess ? (
							<div className={css.stakeBtnsWrapper}>
								<button
									className={css.stakeBtnPurple}
									onClick={() => {
										// @ts-ignore
										onClose()
									}}
								>
									Cancel
								</button>
								<button
									className={css.stakeBtnGrey}
									onClick={handleWithdraw}
									disabled={isPending || isGenesisWithdrawLoading}
								>
									{isPending || isGenesisWithdrawLoading ? <Loader /> : "Proceed"}
								</button>
							</div>
						) : (
							<button className={css.stakeBtnPurple} onClick={onProceed}>
								{t("staking.stakeSubModal.excellent")}
							</button>
						)}
					</div>
				</div>
			</Zoom>
		</Modal>
	)
}
