import closedBetaImg from "../../../../assets/images/banners/closedBeta.png"
import SidebarBanner from "../SidebarBanner/SidebarBanner"
import BetaCodeModal from "./BetaCodeModal/BetaCodeModal"
import { useBetaStore } from "../../../../services/beta/store"

const Beta = () => {
	const { isModalOpen, actions } = useBetaStore()

	return (
		<>
			<SidebarBanner
				className={`sidebar__banner sidebar-banner_medium`}
				bgImageUrl={closedBetaImg}
				onClick={() => actions.setModalOpen(true)}
			/>

			{isModalOpen && <BetaCodeModal />}
		</>
	)
}

export default Beta
