type IConvexityProps = {
	fill?: string
	stroke?: string
	className?: string
}

export const Convexity: React.FC<IConvexityProps> = ({ fill = "#090A17", stroke = "#1E1E20", className }) => {
	return (
		<svg
			className={className}
			width="120"
			height="22"
			viewBox="0 0 120 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M119.85 0.5V1.5C108.342 1.5 99.311 5.90877 90.7577 10.5647L89.7335 11.1244C89.5631 11.2178 89.3929 11.3112 89.2228 11.4046L88.2041 11.9648C87.9039 12.1299 87.6042 12.2948 87.3041 12.4597L86.0694 13.1361C77.9791 17.5519 69.9891 21.5 60.4156 21.5C50.0022 21.5 41.4653 16.8261 32.6316 11.9659C32.4621 11.8726 32.2924 11.7793 32.1227 11.6859L31.1023 11.1254L30.0781 10.5657C21.525 5.90939 12.4934 1.5 0.981445 1.5V0.5H119.85Z"
				fill={fill}
				stroke={stroke}
			/>
		</svg>
	)
}
